.OrderPay {
  background-color: var(--theme);
  width: 100%;
  height: 100%;
  position: absolute;
  &-seperate {
      width: 325px;
      background-color: var(--theme);
      display: flex;
      align-items: center;
      &-leftSemiCircle {
          width: 29px;
          height: 29px;
          position: absolute;
          border-radius: 14.5px;
          transform: translateX(-14.5px);
          background-color: var(--theme)
      }
      &-rightSemiCircle {
          width: 29px;
          height: 29px;
          position: absolute;
          border-radius: 14.5px;
          transform: translateX(310.5px);
          background-color: var(--theme)
      }
      img {
          width: 325px;
          height: 58.5px;
      }
  }
  &-content {
      .bottom {
          width: 375px;
          height: 2.4rem;
          background: linear-gradient(180deg, rgba(0,185,239,0) 0%, var(--theme) 100%);
          position: absolute;
          transform: translate(-50%, -100px);
          position: absolute;
          left: 50%;
      }
      background-color: var(--theme);
      width: 100%;
      text-align: -webkit-center;
      position: absolute;
      &-title {
          line-height: 18px;
          font-size: 18px;
          padding-top: 15.5px;
          padding-bottom: 44.5px;
          color: white;
          font-weight: 600;
      }
      &-avator {
          top: 54.5px;
          left: 50%;
          transform: translate(-50%);
          width: 50px;
          height: 50px;
          border-radius: 25px;
          background-color: #fff;
          position: absolute;
      }
      &-payButton {
          border-radius: 24px;
          background-color: #ffb01d;
          border: 0;
          color: white;
          font-weight: 600;
          font-size: 16px;
          height: 48px;
          width: 200px;
          transform: translate(-50%, 0);
          position: absolute;
          left: 50%
      }
      &-agreement {
          color: white;
          font-size: 12px;
          line-height: 16.5px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          transform: translate(-50%, 60px);
          left: 50%;
          &-radio {
              height: 13px;
              width: 13px;
              margin-right: 5px;
          }
          &-link {
              color: #fff;
          }
      }
  }
  &-halfTop {
      background-color: white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 325px;
      height: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-const {
          padding-top: 48px;
          font-size: 14px;
          color: #0d2236;
          line-height: 20px;
      }
      &-money {
          margin: 10px 0;
      }
      &-yuan {
          font-size: 12px;
          color: #0d2236; 
          padding-right: 2px;
          margin: 20px 0;
      }
      &-amount {
          font-size: 30px;
          color: #0d2236;
          padding-bottom: 1.5px;
      }
      &-waitForPay {
          color: #5e7284;
          font-size: 14px;
          line-height: 16px;
      }
      &-overTime {
          color: #ff7575;
          font-size: 14px;
          line-height: 16px;
      }
      &-notSupport {
          color: #ff7575;
          font-size: 14px;
          line-height: 16px;
      }
      &-paying {
          color: #0d2236;
          font-size: 14px;
          line-height: 16px;
      }
      &-success {
          color: #1dc26d;
          font-size: 20px;
          line-height: 28px;
          font-weight: 600;
      }
  }
  &-halfBottom {
      padding-top: 12.5px;
      height: 298.5px;
      background-color: white;
      width: 325px;
      margin-bottom: 72px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      &-deal {
          padding-bottom: 18.5px;
      }
      &-infoLine {
          display: block;
          text-align: left;
          width: 252px;
          font-size: 14px;
          margin-bottom: 4.5px;
      }
  }
  &-dialog {
      width: 306px;
      height: 181px;
      background-color: white;
      border-radius: 10px;
      &-div {
          position: fixed;
          z-index: 3;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba($color: #000000, $alpha: 0.7);
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      &-title {
          color: #0d2236;
          line-height: 16px;
          font-size: 16px;
          padding-top: 26px;
          padding-bottom: 15px;
          font-weight: 600;
      }
      &-content {
          width: 182px;
          height: 40px;
          color: #0d2236;
          font-size: 14px;
      }
      &-restartButton {
          margin: 20px 7px 26px 22px;
          width: 124px;
          height: 38px;
          color: var(--theme);
          background-color: white;
          border-color: var(--theme);
          border: 0.5px solid var(--theme);
          border-radius: 4px;
          font-size: 14px;
      }
      &-continueButton {
          margin: 20px 22px 26px 7px;
          width: 124px;
          height: 38px;
          color: white;
          background-color: var(--theme);
          border: 0.5px solid var(--theme);
          border-radius: 4px;
          font-size: 14px;
      }
  }
}