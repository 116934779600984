.dialog {
    width: 306px;
    background-color: white;
    border-radius: 10px;
    &-div {
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.7);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-title {
        text-align: center;
        color: #0d2236;
        line-height: 16px;
        font-size: 16px;
        padding-top: 26px;
        padding-bottom: 15px;
        font-weight: 600;
    }
    &-content {
        width: 182px;
        color: #0d2236;
        font-size: 14px;
        margin: 0 auto;
    }
    &-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        margin-top: 20px;
        margin-bottom: 26px;
    }
    &-restartButton {
        width: 124px;
        height: 38px;
        flex: 1;
        border-radius: 4px;
        font-size: 14px;
        color: var(--theme);
        background-color: white;
        border-color: var(--theme);
        border: 0.5px solid var(--theme);
        margin: 0 7px;
    }
    &-continueButton {
        width: 124px;
        height: 38px;
        flex: 1;
        border-radius: 4px;
        font-size: 14px;
        color: white;
        background-color: var(--theme);
        border: 0.5px solid var(--theme);
        margin: 0 7px;
    }
    // &-restartButton {
    //     margin: 20px 7px 26px 22px;
    //     width: 124px;
    //     height: 38px;
    //     color: var(--theme);
    //     background-color: white;
    //     border-color: var(--theme);
    //     border: 0.5px solid var(--theme);
    //     border-radius: 4px;
    //     font-size: 14px;
    // }
    // &-continueButton {
    //     margin: 20px 22px 26px 7px;
    //     width: 124px;
    //     height: 38px;
    //     color: white;
    //     background-color: var(--theme);
    //     border: 0.5px solid var(--theme);
    //     border-radius: 4px;
    //     font-size: 14px;
    // }
}
